var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',[_c('h5',[_vm._v(_vm._s(_vm.title))]),_c('ul',{staticClass:"list-unstyled"},[_c('li',[_c('b-link',{attrs:{"to":{ name: 'Sell' }}},[_vm._v(_vm._s(_vm.$t("navBar.label.sellers")))]),_c('i',{staticClass:"fa fa-chevron-right text-primary",attrs:{"aria-hidden":"true"}})],1),(_vm.type != 'inspection-agents')?_c('li',[_c('b-link',{attrs:{"to":{
          name: 'Contact',
          params: { type: 'inspection-agents' },
        }}},[_vm._v(_vm._s(_vm.$t("navBar.label.inspectionAgents")))]),_c('i',{staticClass:"fa fa-chevron-right text-primary",attrs:{"aria-hidden":"true"}})],1):_vm._e(),(_vm.type != 'freight-forwarders')?_c('li',[_c('b-link',{attrs:{"to":{
          name: 'Contact',
          params: { type: 'freight-forwarders' },
        }}},[_vm._v(_vm._s(_vm.$t("navBar.label.freightForwarders")))]),_c('i',{staticClass:"fa fa-chevron-right text-primary",attrs:{"aria-hidden":"true"}})],1):_vm._e(),(_vm.type != 'customs-brokers')?_c('li',[_c('b-link',{attrs:{"to":{
          name: 'Contact',
          params: { type: 'customs-brokers' },
        }}},[_vm._v(_vm._s(_vm.$t("navBar.label.customsBrokers")))]),_c('i',{staticClass:"fa fa-chevron-right text-primary",attrs:{"aria-hidden":"true"}})],1):_vm._e(),(_vm.type != 'sales-representatives')?_c('li',[_c('b-link',{attrs:{"to":{
          name: 'Contact',
          params: { type: 'sales-representatives' },
        }}},[_vm._v(_vm._s(_vm.$t("navBar.label.salesRepresentatives")))]),_c('i',{staticClass:"fa fa-chevron-right text-primary",attrs:{"aria-hidden":"true"}})],1):_vm._e(),(_vm.type != 'jobs')?_c('li',[_c('b-link',{attrs:{"to":{ name: 'Contact', params: { type: 'jobs' } }}},[_vm._v(_vm._s(_vm.$t("navBar.label.jobs")))]),_c('i',{staticClass:"fa fa-chevron-right text-primary",attrs:{"aria-hidden":"true"}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }