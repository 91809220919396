<template>
  <b-card>
    <h5>{{ title }}</h5>
    <ul class="list-unstyled">
      <li>
        <b-link :to="{ name: 'Sell' }">{{ $t("navBar.label.sellers") }}</b-link>
        <i class="fa fa-chevron-right text-primary" aria-hidden="true"></i>
      </li>
      <li v-if="type != 'inspection-agents'">
        <b-link
          :to="{
            name: 'Contact',
            params: { type: 'inspection-agents' },
          }"
          >{{ $t("navBar.label.inspectionAgents") }}</b-link
        >
        <i class="fa fa-chevron-right text-primary" aria-hidden="true"></i>
      </li>
      <li v-if="type != 'freight-forwarders'">
        <b-link
          :to="{
            name: 'Contact',
            params: { type: 'freight-forwarders' },
          }"
          >{{ $t("navBar.label.freightForwarders") }}</b-link
        >
        <i class="fa fa-chevron-right text-primary" aria-hidden="true"></i>
      </li>
      <li v-if="type != 'customs-brokers'">
        <b-link
          :to="{
            name: 'Contact',
            params: { type: 'customs-brokers' },
          }"
          >{{ $t("navBar.label.customsBrokers") }}</b-link
        >
        <i class="fa fa-chevron-right text-primary" aria-hidden="true"></i>
      </li>
      <li v-if="type != 'sales-representatives'">
        <b-link
          :to="{
            name: 'Contact',
            params: { type: 'sales-representatives' },
          }"
          >{{ $t("navBar.label.salesRepresentatives") }}</b-link
        >
        <i class="fa fa-chevron-right text-primary" aria-hidden="true"></i>
      </li>
      <li v-if="type != 'jobs'">
        <b-link :to="{ name: 'Contact', params: { type: 'jobs' } }">{{
          $t("navBar.label.jobs")
        }}</b-link>
        <i class="fa fa-chevron-right text-primary" aria-hidden="true"></i>
      </li>
      <!-- <li class="font-weight-bold">
                  <i class="fa fa-chevron-right text-primary mr-2" aria-hidden="true"></i>
                  <b-link
                      :to="{ name: 'Contact',params:{type:'contact'} }"
                    >{{ $t("topbar.label.contactUs") }}</b-link>
                </li> -->
    </ul>
  </b-card>
</template>

<script>
export default {
  computed: {
    type() {
      return this.$route.params.type;
    },
  },
  props: ["title"],
};
</script>

<style scoped lang="scss">
.card-body {
  padding: 40px 64px;
  @media (max-width: 991px) {
    padding: 24px;
  }
  @media (max-width: 575px) {
    padding: 24px 16px;
  }
}
h5 {
  margin-bottom: 24px;
}
ul {
  padding: 0;
  li {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  i {
    margin-left: 16px;
    font-size: 12px;
    vertical-align: middle;
  }
}
</style>
